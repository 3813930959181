import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { isEmpty, has } from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"
// import Outro from "../components/outro"

const Press = ({ data, locale }) => {
  const { title, description, brands /*outro*/ } = data.mdx.frontmatter

  return (
    <Layout locale={locale}>
      <div className="shell">
        <h1>
          {title}
          <span class="dotRed">.</span>
        </h1>
        <p className="description">{description}</p>
        <div>
          {!isEmpty({ brands }) && (
            <div className="presses">
              {brands.map((brand, index) => (
                <div key={index} className="press">
                  {has(brand, "image", "name") && (
                    <div className="presse__icon">
                      <GatsbyImage
                        image={brand.image.childImageSharp.gatsbyImageData}
                        alt={`brand_image_${index}`}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* {outro && <Outro key="#outro" {...outro} />} */}
    </Layout>
  )
}

export const pressQuery = graphql`
  query PressQuery($locale: String!) {
    mdx(
      fileAbsolutePath: { regex: "/static-pages/press/" }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        brands {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        outro {
          title
          buttonLeft {
            link
            caption
          }
        }
      }
    }
  }
`

Press.propTypes = {
  data: PropTypes.object,
}

Press.defaultProps = {
  data: [],
}

export default Press
